$(function() {
  var buttons, i, shareButton, shareButtons;
  $("#candidate_details fieldset.inputs:nth-child(3) ol li:nth-child(2) label").html('Mobile<abbr title="required">*</abbr>');
  $("#candidate_details fieldset.inputs:nth-child(3) ol li:nth-child(3) label").html('Phone<abbr title="required">*</abbr>');
  if ($("#job_description").length > 0) {
    $(".job-tab.application-form").on("click", function() {
      $("form#new_application").show();
      $("#job_description").hide();
      $(".job-tab.application-form").addClass("active");
      $(".job-tab.role-overview").removeClass("active");
    });
    $("#apply-now-button").on("click", function() {
      $(".job-tab.application-form").click();
      $(document).scrollTop(350);
    });
    $(".job-tab.role-overview").on("click", function() {
      $("form#new_application").hide();
      $("#job_description").show();
      $(".job-tab.application-form").removeClass("active");
      $(".job-tab.role-overview").addClass("active");
    });
  } else {
    $(".job-tabs").hide();
    $("form#new_application").show();
  }
  $("#job_header").appendTo("#header-destination > .container > div.row > div");
  $("#job_entity").prependTo("#job_header > .container > h2");
  $("#new_application > div.container").prepend('<div id="li-dest"></div>');
  $("#apply-with-indeed").appendTo("#li-dest");
  $("#apply-with-seek").appendTo("#li-dest");
  $("#signin-with-linkedin").appendTo("#li-dest");
  buttons = $("fieldset.actions.buttons ol li");
  buttons.map(function(i2, e) {
    if ($(e).find("button").text().trim() === "Start over") {
      $(e).hide();
    }
  });
  shareButtons = document.querySelectorAll(".st-custom-button[data-network]");
  i = 0;
  while (i < shareButtons.length) {
    shareButton = shareButtons[i];
    shareButton.addEventListener("click", function(e) {
      var elm, network;
      elm = e.target;
      network = elm.dataset.network;
      console.log("share click: " + network);
    });
    i++;
  }
});
